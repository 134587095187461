<template>
  <q-card flat class="row justify-center q-mt-md">
    <q-dialog v-model="showModal" persistent>
      <q-card style="width: 700px; max-width: 80vw">
        <q-toolbar class="bg-primary text-white">
          <q-toolbar-title> Formato do arquivo</q-toolbar-title>
          <q-btn flat round dense icon="mdi-close" @click="showModal = false" />
        </q-toolbar>
        <q-card-section class="row q-col-gutter-md">
          <div class="col-12">
            <div class="text-body q-mb-md">
              O arquivo deverá ser separado por ";" e deverá conter as seguintes <b>colunas:</b>
            </div>
            <div><b>ID da campanha</b></div>
            <div><b>Título da campanha</b></div>
            <div><b>Descrição</b></div>
            <div><b>URL destino</b></div>
            <div><b>Data e hora de ativação: </b>No formato dd/mm/aaaa hh:mm</div>
            <div><b>Data e hora de expiração: </b>No formato dd/mm/aaaa hh:mm</div>
            <div><b>Código promocional</b></div>
            <div>
              <b>Canal:</b> Contendo os id's dos canais que serão adicionados ao criativo, separados por vírgula, ou
              vazia para adicionar todos os canais da campanha.
            </div>
          </div>
        </q-card-section>
        <q-card-actions>
          <p class="text-red-5">
            <b>ATENÇÃO:</b> Caso algum dado não esteja preenchido corretamente,
            <b>o criativo será ignorado pela plataforma!</b>
          </p>
        </q-card-actions>
        <q-card-actions align="right">
          <q-btn
            no-caps
            class="q-px-md"
            unelevated
            label="Download de modelo"
            outline
            color="negative"
            href="https://afilio-v3.s3.amazonaws.com/Template_Criativo_Batch.csv"
          />
          <q-btn no-caps class="q-px-md" unelevated label="Fechar" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-card-section class="q-pa-none">
      <q-form @submit.prevent="onFormSubmit">
        <q-stepper
          v-model="step"
          ref="formAdvertiser"
          color="primary"
          animated
          header-nav
          alternative-labels
          flat
          class="full-width"
        >
          <q-step name="select" title="Tipo" icon="mdi-domain" style="width: 800px">
            <q-card flat>
              <q-card-section class="row q-col-gutter-md q-mt-md">
                <div class="col-12">
                  <span class="text-weight-medium">Passo 1</span>
                  <q-select
                    v-model="creativeSelections.selectedCampaign"
                    use-input
                    input-debounce="200"
                    @filter="filterCampaigns"
                    :options="campaignsFiltered"
                    :rules="[isRequired]"
                    label="Campanha"
                    option-label="name"
                    option-value="id"
                    outlined
                    ref="campaign"
                    clearable
                    clear-icon="mdi-close"
                  />
                </div>
                <div class="col-12">
                  <span class="text-weight-medium">Passo 2</span>
                  <div class="q-gutter-sm">
                    <q-radio v-model="modo" val="m" label="Manual" />
                    <q-radio v-model="modo" val="c" label="Cupom/Oferta em lote" />
                  </div>
                </div>
                <div class="col-12" v-if="modo == 'm'">
                  <span class="text-weight-medium">Passo 3</span>
                  <q-select
                    v-model="creativeSelections.creativeType"
                    use-input
                    input-debounce="200"
                    @filter="filterCreatives"
                    :options="creativeTypeFiltered"
                    :rules="[isRequired]"
                    label="Tipo de criativo"
                    option-label="description"
                    option-value="id"
                    outlined
                    clearable
                    clear-icon="mdi-close"
                  />
                </div>
                <div class="col-12" v-if="modo == 'c'">
                  <span class="text-weight-medium">Passo 3</span>
                  <q-banner class="bg-info text-white q-mb-md" rounded>
                    <q-item>
                      <q-item-section top avatar>
                        <q-icon name="mdi-information-outline" size="lg" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label class="text-weight-medium">Atenção ao formato correto do arquivo! </q-item-label>

                        <q-item-label caption lines="6" class="text-white"
                          >Em caso de dúvidas
                          <q-btn dense flat color="white" label="Clique aqui" @click="showModal = true" />.
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-banner>
                  <q-uploader ref="uploader" flat bordered accept=".csv" class="full-width" @added="getCSV">
                    <template v-slot:list="scope">
                      <q-list separator>
                        <div class="text-center" v-if="scope.files.length == 0">
                          <div class="text-h6 text-primary">Arraste e solte seus documentos aqui!</div>
                          <div class="text-caption">...ou clique para selecionar os arquivos do computador</div>
                        </div>
                        <q-item v-for="file in scope.files" :key="file.name">
                          <q-item-section>
                            <q-item-label class="full-width ellipsis">
                              {{ file.name }}
                            </q-item-label>

                            <q-item-label caption>
                              Status:
                              {{ file.__status }}
                            </q-item-label>

                            <q-item-label caption>
                              {{ file.__sizeLabel }}
                              /
                              {{ file.__progressLabel }}
                            </q-item-label>
                          </q-item-section>

                          <q-item-section v-if="file.__img" thumbnail class="gt-xs">
                            <img :src="file.__img.src" />
                          </q-item-section>

                          <q-item-section top side>
                            <q-btn
                              class="gt-xs"
                              size="12px"
                              flat
                              dense
                              round
                              icon="delete"
                              @click="scope.removeFile(file)"
                            />
                          </q-item-section>
                        </q-item>
                      </q-list>
                    </template>
                  </q-uploader>
                </div>
                <div class="col-12" v-if="creativeSelections.creativeType?.name == 'image'">
                  <span class="text-weight-medium">Passo 4</span>
                  <q-file
                    outlined
                    ref="images"
                    v-model="images"
                    :rules="[isRequiredImage]"
                    multiple
                    class="file-upload"
                    style="z-index: 1 !important"
                  >
                    <template v-slot:prepend v-if="images.length <= 0">
                      <div class="full-width bg-transsparent absolute-center text-center">
                        <div class="text-h6 text-primary">Arraste e solte seus documentos aqui!</div>
                        <div class="text-caption">...ou clique para selecionar os arquivos do computador</div>
                      </div>
                    </template>

                    <template v-slot:file="{ index, file }">
                      <div class="q-pa-sm">
                        <q-card flat bordered bg-transparent>
                          <q-img :src="parceToUrl(file)" style="width: 216px; height: 200px" contain>
                            <div class="absolute-bottom text-caption text-center">
                              {{ file.name }} -
                              {{ formatBytes(file.size) }}
                            </div>
                          </q-img>
                          <q-btn
                            icon="mdi-close"
                            class="absolute text-white bg-negative"
                            style="top: 15px; right: 0px; transform: translateY(-50%)"
                            dense
                            unelevated
                            @click.prevent="images.length == 1 ? (images = []) : images.splice(index, 1)"
                          />
                        </q-card>
                      </div>
                    </template>
                  </q-file>
                </div>
              </q-card-section>
            </q-card>
          </q-step>

          <q-step
            name="coupon"
            title="Cupom"
            icon="mdi-card-account-phone-outline"
            v-if="step == 'coupon'"
            style="width: 800px"
          >
            <q-card flat>
              <q-card-section class="row q-col-gutter-md q-pa-none q-pt-lg">
                <CupomCreative />
              </q-card-section>
            </q-card>
          </q-step>

          <q-step
            name="emailmkt"
            title="Email Marketing"
            icon="assignment"
            v-if="step == 'emailmkt'"
            style="width: 800px"
          >
            <q-card flat>
              <q-card-section class="row q-col-gutter-md q-pa-none">
                <emailmkt-creative />
              </q-card-section>
            </q-card>
          </q-step>

          <q-step name="deal" title="Oferta" icon="assignment" v-if="step == 'deal'" style="width: 800px">
            <q-card flat>
              <q-card-section class="row q-col-gutter-md q-pa-none">
                <deal-creative />
              </q-card-section>
            </q-card>
          </q-step>

          <q-step
            name="hyperlink"
            title="Link de texto"
            icon="assignment"
            v-if="step == 'hyperlink'"
            style="width: 800px"
          >
            <q-card flat>
              <q-card-section class="row q-col-gutter-md q-pa-none">
                <hyperlink-creative />
              </q-card-section>
            </q-card>
          </q-step>

          <q-step name="image" title="Banner de imagem" icon="assignment" v-if="step == 'image' && images.length > 0">
            <q-card flat>
              <div class="text-h5">Campanha:{{ creativeSelections.selectedCampaign.name }}</div>
              <span class="text-body2">Tipo de Criativo Selecionado:</span>
              {{ creativeSelections.creativeType.description }}
              <q-card-section class="row q-col-gutter-lg justify-around q-px-none">
                <image-creative v-for="(item, index) in images" :key="index" :id="index" :images="item" />
              </q-card-section>
            </q-card>
          </q-step>
        </q-stepper>
        <q-separator inset />
        <q-card-actions align="right" class="q-pa-md">
          <q-btn
            outline
            v-if="step !== 'select'"
            label="Voltar"
            no-caps
            padding="sm lg"
            color="primary"
            type="button"
            @click.prevent="step = 'select'"
          />
          <q-btn
            unelevated
            color="primary"
            label="Avançar"
            v-if="step === 'select'"
            no-caps
            padding="sm lg"
            type="submit"
          />
          <q-btn
            v-if="step !== 'select'"
            unelevated
            label="Salvar"
            no-caps
            padding="sm lg"
            color="primary"
            type="submit"
          />
        </q-card-actions>
      </q-form>
    </q-card-section>
  </q-card>
</template>

<script>
import CreativeMixin from '../../mixins/CreativeMixin';
import CreativesService from '../../services/CreativesService';
import CupomCreative from './formCreativeTypes/CupomCreative.vue';
import EmailmktCreative from './formCreativeTypes/EmailmktCreative.vue';
import DealCreative from './formCreativeTypes/DealCreative.vue';
import HyperlinkCreative from './formCreativeTypes/HyperlinkCreative.vue';
import ImageCreative from './formCreativeTypes/ImageCreative.vue';
import AdvertiserService from '@/services/AdvertiserService.js';

export default {
  components: {
    CupomCreative,
    EmailmktCreative,
    DealCreative,
    HyperlinkCreative,
    ImageCreative
  },
  name: 'FormCreative',
  mixins: [CreativeMixin, CreativesService, AdvertiserService],
  data() {
    return {
      images: [],
      campaignsFiltered: [],
      creativeTypeFiltered: [],
      campaigns: [],
      creativeType: [],
      modo: 'm',
      step: 'select',
      formDataCSV: null,
      formsImage: [],
      fileName: '',
      showModal: false
    };
  },
  methods: {
    onFormSubmit() {
      if (this.step === 'select') {
        this.onSelectType(this.creativeSelections.creativeType);
      } else {
        this.onSave();
      }
    },

    isRequiredImage(val) {
      return val.length > 0 || 'É necessário adicionar uma imagem';
    },

    filterCampaigns(val, update) {
      update(this.defaultFilter(val, 'campaignsFiltered', this.campaigns, 'name'));
    },

    filterCreatives(val, update) {
      update(this.defaultFilter(val, 'creativeTypeFiltered', this.creativeType, 'description'));
    },

    async validateFormImage() {
      for (let formImage of this.formCreativeImages) {
        let erro = false;
        let creationData = { ...formImage, id_campaign: this.creativeSelections.selectedCampaign.id };

        if (creationData.expiration_date) {
          // creationData.expiration_date = this.formatDateString(creationData.expiration_date);

          if (creationData.expiration_time) {
            creationData.expiration_date += ' ' + creationData.expiration_time;
          }
        } else if (creationData.expiration_time) {
          this.errorNotify('É preciso selecionar a data de expiração antes de selecionar a hora!');
          return;
        }

        // creationData.activation_date = this.formatDateString(creationData.activation_date);
        if (creationData.activation_time) {
          creationData.activation_date += ' ' + creationData.activation_time;
        }

        if (creationData.id_category) creationData.id_category = creationData.id_category.id;
        if (creationData.id_offer) creationData.id_offer = creationData.id_offer.id;
        if (creationData.id_type.id) creationData.id_type = creationData.id_type.id;
        if (creationData.id_banner_size.id) {
          creationData.id_size = creationData.id_banner_size.id;
        }

        if (creationData.image) creationData.image_url = await this.uploadBannerCreative(formImage.image);
        if (creationData.site && creationData.site.length > 0) {
          creationData.id_site = creationData.site.map((element) => element.id).join(',');
        }

        this.cleanImageCreativeDataAttributes(creationData);
        if (!erro) this.create(creationData);
      }
    },

    cleanImageCreativeDataAttributes(creationData) {
      const attributesToDelete = ['activation_time', 'expiration_time', 'site', 'id_banner_size', 'image', 'id'];
      attributesToDelete.forEach((attribute) => delete creationData[attribute]);
    },

    // formatDateString(str) {
    //   return this.$moment(this.stringToDate(str, 'dd/MM/yyyy', '/')).format('YYYY-MM-DD');
    // },

    validateForm() {
      let creationData = {
        ...this.formCreativeData,
        id_campaign: this.creativeSelections.selectedCampaign.id,
        id_type: this.creativeSelections.creativeType.id
      };

      if (creationData.expiration_date) {
        // creationData.expiration_date = this.formatDateString(creationData.expiration_date);
        if (this.creativeSelections.expiration_time) {
          creationData.expiration_date += ' ' + this.creativeSelections.expiration_time;
        }
      }

      if (this.creativeSelections.expiration_time && !creationData.expiration_date) {
        this.errorNotify('É preciso selecionar a data de expiração antes da hora');
        return;
      }

      // creationData.activation_date = this.formatDateString(creationData.activation_date);
      if (this.creativeSelections.activation_time) {
        creationData.activation_date += ' ' + this.creativeSelections.activation_time;
      }

      if (creationData.site?.length > 0)
        creationData.id_site = this.formCreativeData.site.map((element) => element.id).join(',');

      if (this.creativeSelections.selectedOffer) {
        creationData.id_offer = this.creativeSelections.selectedOffer.id;
      }

      if (this.creativeSelections.selectedCategory) {
        creationData.id_category = this.creativeSelections.selectedCategory.id;
      }

      delete creationData.site;
      this.create(creationData);
    },

    async onSave() {
      if (this.creativeSelections.creativeType.id === 5 && this.formCreativeImages.length > 0) {
        this.validateFormImage();
      } else if (this.creativeSelections.creativeType.id === 5 && !this.formCreativeImages.length) {
        this.errorNotify('Preencha os campos');
      } else {
        this.validateForm();
      }
    },

    getCSV(file) {
      if (!this.creativeSelections.selectedCampaign) {
        this.$refs.uploader.reset();
        this.errorNotify('Selecione uma campanha!');
        return;
      }

      let readCsv = new FileReader();
      readCsv.readAsText(file[0]);

      readCsv.onload = () => {
        let csvList = readCsv.result.split('\n');
        let columns = csvList[0].replaceAll('\r', '').split(';');

        const columnLabel = [
          'campaign_id',
          'title',
          'description',
          'destination_url',
          'activation_date',
          'expiration_date',
          'code'
        ];
        let fileVerify = false;

        columnLabel.forEach((el, index) => {
          el == columns[index] ? (fileVerify = true) : (fileVerify = false);
          if (!fileVerify) return;
        });

        if (fileVerify) {
          this.fileName = `creativeBatch_${this.creativeSelections.selectedCampaign.id}_${this.$moment().format(
            'YYYY_MM_DD'
          )}_${this.$moment().format('H_I_S')}_${this.uuidV4()}.csv`;
          this.formDataCSV = new FormData();
          let [fileCSV] = file;
          this.formDataCSV.append('file', fileCSV);
          this.formDataCSV.append('file_rename', this.fileName);
          this.formDataCSV.append('bucket', 's3CreativeCsv');
        } else {
          this.errorNotify('O arquivo não está no formato correto.');
          this.$refs.uploader.reset();
        }
      };
    },

    onSelectType(type) {
      if (this.modo === 'c') {
        if (!this.formDataCSV) {
          this.errorNotify('É necessário fazer o upload de um arquivo CSV.');
          return;
        }
        this.importCSV();
      } else {
        this.step = type.name;
      }
    },

    async uploadBannerCreative(image) {
      try {
        this.onLoading(true);

        let renameTo = `bannerimage/${this.creativeSelections.selectedCampaign.id}/${this.$moment().format(
          'YYYY-MM-DD'
        )}/${this.uuidV4()}`;

        let form = new FormData();
        form.append('file', image);
        form.append('file_rename', renameTo);
        form.append('bucket', 's3Creative');

        const { status } = await this.uploadBanner(form);

        if (status === 200) {
          return `${process.env.VUE_APP_CREATIVE_S3}${renameTo}`;
        }
      } catch (error) {
        this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async importCSV() {
      try {
        this.onLoading(true);

        const { status } = await this.$http.post('/v2/upload/file', this.formDataCSV, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        if (status === 200 || status === 201) {
          const { status: status2 } = await this.$http.post('/redirect', {
            app: 'creative',
            path: '/api/creative/upload',
            params: JSON.stringify({
              data: {
                campaign_id: this.creativeSelections.selectedCampaign.id,
                archive_name: this.fileName
              }
            })
          });

          if (status2 === 200 || status2 === 201) {
            this.fileName = '';
            this.successNotify(
              'Você acabou de subir o material para criação de novos criativos, por favor confira se todo o material foi criado como solicitado! Caso algum criativo não tenha sido gerado, por favor confira os dados de criação cadastrados no arquivo'
            );
            this.$refs.uploader.reset();
          }
        }
      } catch (error) {
        if (error.response?.data?.file_upload?.file?.length > 0)
          error.response.data.file_upload.file.forEach((item) => {
            this.errorNotify(item);
          });
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async create(data) {
      this.onLoading(true);
      try {
        const { status } = await this.createCreative(data);

        if (status == 200) {
          this.$router.push({ name: 'CreativeList' });
          this.successNotify('Criativo criado com sucesso!');
        }
      } catch (error) {
        this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getCampaigns() {
      this.onLoading(true);
      try {
        const { data, status } = await this.getAllCampaigns();
        if (status == 200) this.campaigns = data;
      } catch (error) {
        this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getCreativeType() {
      this.onLoading(true);
      try {
        const { data, status } = await this.listCreativeTypes();
        if (status == 200) this.creativeType = data;
      } catch (error) {
        this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },

  created() {
    this.getCampaigns();
    this.getCreativeType();
  },

  destroyed() {
    this.ActionSetFormCreativeData({});
    this.ActionSetCreativeSelections({});
    this.ActionSetFormCreativeImages([]);
  }
};
</script>
<style>
.creative .q-stepper__header {
  display: none;
}

.file-upload .q-field__control,
.file-upload {
  min-height: 128px !important;
}
</style>
