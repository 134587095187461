<template>
  <form-creative-default-inputs>
    <div class="col-12 text-right">
      <q-input
        v-model="formCreativeData.html"
        type="textarea"
        label="Html *"
        :rules="[isRequired]"
        outlined
        @input="validateUrl"
      >
        <template v-slot:append></template>
      </q-input>
      <q-btn label="Macros" color="primary" class="q-mt-sm q-mr-lg" no-caps unelevated @click="onShowMacros">
        <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Macros </q-tooltip>
      </q-btn>
      <MacroListCreative :showM="showMacros" @closemacro="showMacros = false" />
      <q-btn color="primary" class="q-mt-sm q-mr-lg" icon="fullscreen" @click="openFullScreen" no-caps unelevated />

      <q-btn color="primary" class="q-mt-sm" label="Visualizar" @click="openEmailTab" no-caps unelevated />
    </div>
    <div class="col-12">
      <q-input
        v-model="formCreativeData.subject"
        type="text"
        label="Assunto *"
        :rules="[(val) => !!val || 'Este campo é obrigatório.']"
        outlined
      />
    </div>
    <div class="col-12">
      <q-input
        v-model="formCreativeData.sender"
        type="text"
        label="Sender *"
        :rules="[(val) => !!val || 'Este campo é obrigatório.']"
        outlined
      />
    </div>
    <q-dialog
      v-model="dialogFullScreen"
      persistent
      :maximized="maximizedToggle"
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card>
        <q-bar>
          <q-space />

          <q-btn
            v-if="maximizedToggle"
            dense
            flat
            icon="minimize"
            @click="maximizedToggle = false"
            :disable="!maximizedToggle"
          >
            <q-tooltip content-class="bg-white text-primary">Minimize</q-tooltip>
          </q-btn>
          <q-btn
            v-if="!maximizedToggle"
            dense
            flat
            icon="crop_square"
            @click="maximizedToggle = true"
            :disable="maximizedToggle"
          >
            <q-tooltip content-class="bg-white text-primary">Maximize</q-tooltip>
          </q-btn>
          <q-btn dense flat icon="close" v-close-popup>
            <q-tooltip content-class="bg-white text-primary">Close</q-tooltip>
          </q-btn>
        </q-bar>

        <q-card-section>
          <q-input v-model="formCreativeData.html" type="textarea" label="Html" outlined>
            <template v-slot:append></template>
          </q-input>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog
      v-model="dialogFullScreen"
      persistent
      :maximized="maximizedToggle"
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card>
        <q-bar>
          <q-space />

          <q-btn
            v-if="maximizedToggle"
            dense
            flat
            icon="minimize"
            @click="maximizedToggle = false"
            :disable="!maximizedToggle"
          >
            <q-tooltip content-class="bg-white text-primary">Minimize</q-tooltip>
          </q-btn>
          <q-btn
            v-if="!maximizedToggle"
            dense
            flat
            icon="crop_square"
            @click="maximizedToggle = true"
            :disable="maximizedToggle"
          >
            <q-tooltip content-class="bg-white text-primary">Maximize</q-tooltip>
          </q-btn>
          <q-btn dense flat icon="close" v-close-popup>
            <q-tooltip content-class="bg-white text-primary">Close</q-tooltip>
          </q-btn>
        </q-bar>

        <q-card-section>
          <q-input v-model="formCreativeData.html" type="textarea" label="Html" outlined>
            <template v-slot:append></template>
          </q-input>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="alertUrl" persistent>
      <q-card>
        <q-toolbar class="bg-primary text-white">
          <q-toolbar-title>Atenção</q-toolbar-title>
        </q-toolbar>

        <q-card-section>
          <div class="text-h6">
            Existem urls incorretas na peça de email marketing, corrija as urls abaixo, caso contrário ocorrerão erros
            quando houver tentativas de gerar tags
          </div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-item v-for="(failUrl, index) in failUrls" :key="index">
            <q-item-section>
              <q-input outlined readonly type="text" :value="failUrl" />
            </q-item-section>
          </q-item>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn no-caps label="OK" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </form-creative-default-inputs>
</template>

<script>
import MacroListCreative from './MacroListCreative.vue';
import FormCreativeDefaultInputs from './FormCreativeDefaultInputs.vue';
import CreativeMixin from '@m/CreativeMixin.js';

export default {
  name: 'EmailMktCreative',
  mixins: [CreativeMixin],
  components: {
    MacroListCreative,
    FormCreativeDefaultInputs
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      showMacros: false,
      sitesList: [],
      sitesFiltered: [],
      date: null,
      dialogFullScreen: false,
      maximizedToggle: true,
      alertUrl: false,
      failUrls: []
    };
  },
  watch: {
    form: {
      handler() {
        this.$emit('update', this.form);
      },
      deep: true
    }
  },
  methods: {
    onShowMacros() {
      this.showMacros = !this.showMacros;
    },

    validateUrl() {
      let doc = document.createElement('html');
      doc.innerHTML = this.formCreativeData.html;

      this.failUrls = [];

      let links = doc.getElementsByTagName('a');
      for (let i = 0; i < links.length; i++) {
        if (!this.urlIsValid(links[i].getAttribute('href'))) {
          this.failUrls.push(links[i].getAttribute('href'));
        }
      }

      links = doc.getElementsByTagName('img');
      for (let i = 0; i < links.length; i++) {
        if (!this.urlIsValid(links[i].getAttribute('src'))) {
          this.failUrls.push(links[i].getAttribute('src'));
        }
      }

      if (this.failUrls.length > 0) {
        console.log(this.failUrls);
        this.alertUrl = true;
      }
    },

    urlIsValid(url) {
      // Nosso regex Oficial
      // https://regex101.com/r/a9twc2/1
      let pattern = new RegExp(
        '((https?|ftp)\\:\\/\\/)?([a-z0-9+!*(),;?&=\\$_.-]+(\\:[a-z0-9+!*(),;?&=\\$_.-]+)?@)?([:\\/\\a-z0-9-.]*)\\.([a-z]{2,5})(\\:[0-9]{2,5})?(\\/([a-z0-9+\\$_-]\\.?)+)*\\/?(\\?[a-z+&\\$_.-][a-z0-9!;:@&%=+\\/\\$_.-\\|-]*)?(#[a-z_.-][a-z0-9+\\$_.-]*)?'
      );

      return pattern.test(url);
    },

    openEmailTab() {
      let wnd = window.open('about:blank', '_blank');
      wnd.document.write(this.formCreativeData.html);
    },

    openFullScreen() {
      this.dialogFullScreen = !this.dialogFullScreen;
    }
  }
};
</script>
