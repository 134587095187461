<template>
    <form-creative-default-inputs>
        <div class="col-12">
            <q-input
                v-model="formCreativeData.description"
                type="text"
                label="Texto *"
                maxlength="254"
                :rules="[
                    isRequired,
                    (val) => val.length < 254 || 'Limite de 254 caracteres',
                ]"
                outlined
            />
        </div>
        <div class="col-12">
            <q-input
                v-model="formCreativeData.code"
                type="text"
                label="Código"
                outlined
            />
        </div>
        <div class="col-12">
            <q-input
                v-model="formCreativeData.destination_url"
                type="url"
                label="Url do redirecionamento *"
                :rules="[isRequired]"
                outlined
                autogrow
            >
                <template v-slot:append>
                    <q-icon
                        name="mdi-help-circle-outline"
                        class="cursor-pointer"
                        size="1.3em"
                        color="primary"
                        @click="onShowMacros"
                    >
                        <q-tooltip
                            content-class="bg-secondary text-white"
                            content-style="font-size: 12px"
                        >
                            Macros
                        </q-tooltip>
                    </q-icon>
                    <MacroListCreative
                        :showM="showMacros"
                        @closemacro="showMacros = false"
                    />
                </template>
            </q-input>
        </div>
    </form-creative-default-inputs>
</template>

<script>
import MacroListCreative from "./MacroListCreative.vue";
import FormCreativeDefaultInputs from "./FormCreativeDefaultInputs.vue";
import CreativeMixin from "@m/CreativeMixin.js";

export default {
    name: "DealCretive",
    mixins: [CreativeMixin],
    components: {
        MacroListCreative,
        FormCreativeDefaultInputs,
    },
    data() {
        return {
            showMacros: false,
        };
    },
    methods: {
        onShowMacros() {
            this.showMacros = !this.showMacros;
        },
    },
};
</script>

<style></style>
