<template>
  <q-page padding>
    <form-creative />
  </q-page>
</template>

<script>
import CreativeMixin from '../../mixins/CreativeMixin';
import FormCreative from '../../components/creative/FormCreative.vue';
export default {
  mixins: [CreativeMixin],
  components: { FormCreative }
};
</script>
