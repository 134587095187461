<template>
  <q-card flat>
    <q-card-section>
      <div class="text-h5">Campanha:{{ creativeSelections.selectedCampaign?.name }}</div>
      <span class="text-body2">
        Tipo de Criativo Selecionado:
        {{ creativeSelections.creativeType?.description }}
      </span>
    </q-card-section>

    <q-card-section class="row q-col-gutter-md">
      <div class="col-12">
        <q-input
          v-model="formCreativeData.title"
          label="Titulo *"
          maxlength="200"
          :rules="[isRequired, (val) => val.length < 200 || 'Limite de 200 caracteres']"
          outlined
        />
      </div>

      <slot>
        <!-- Campos específicos de cada tipo de criativo  -->
      </slot>

      <div class="col-12">
        <date-input
          :v-model="activation_date"
          @update="activation_date = $event"
          outlined
          clearable
          label="Data de Ativação *"
          :rules="[isRequired, isValidDate]"
        />
      </div>

      <div class="col-12">
        <time-input
          :label="'Hora de ativação'"
          @timeUpdate="(val) => (creativeSelections.activation_time = val)"
          :rules="[isValidTime]"
        />
      </div>

      <div class="col-12">
        <date-input
          :v-model="expiration_date"
          @update="expiration_date = $event"
          outlined
          clearable
          label="Data de Expiração"
          mask="YYYY-MM-DD"
          :rules="[isValidDate]"
        />
      </div>

      <div class="col-12">
        <time-input
          label="Hora de Expiração"
          @timeUpdate="(val) => (creativeSelections.expiration_time = val)"
          :rules="[isValidTime]"
        />
      </div>

      <div class="col-12">
        <select-request
          :v-model="creativeSelections.selectedCategory"
          outlined
          label="Categorias"
          endpoint="redirect?app=CREATIVE&path=/api/get/category/filter"
          option-label="name"
          option-value="id"
          @update="(value) => (creativeSelections.selectedCategory = value)"
        />
      </div>

      <div class="col-12">
        <select-request
          :v-model="creativeSelections.selectedOffer"
          outlined
          label="oferta/Sazonalidade"
          endpoint="redirect?app=CREATIVE&path=/api/get/offer/filter"
          option-label="name"
          option-value="id"
          @update="(value) => (creativeSelections.selectedOffer = value)"
        />
      </div>

      <div class="col-12">
        <q-select
          v-model="formCreativeData.site"
          outlined
          multiple
          use-chips
          use-input
          input-debounce="200"
          label="Canal"
          @filter="filterSites"
          :options="sitesFiltered"
          option-label="name"
          option-value="id"
          clearable
          clear-icon="mdi-close"
        />
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import CreativeMixin from '@m/CreativeMixin.js';
import SelectRequest from '@/widgets/inputsGlobal/SelectRequest.vue';
import DateInput from '../../../widgets/inputsGlobal/DateInput.vue';
import TimeInput from '../../../widgets/inputsGlobal/TimeInput.vue';
import AffiliateService from '../../../services/AffiliateService.js';

export default {
  mixins: [CreativeMixin, AffiliateService],
  components: { DateInput, SelectRequest, TimeInput },
  name: 'FormCreativeBoilerplate',

  data() {
    return {
      sitesFiltered: [],
      activation_date: null,
      expiration_date: null
    };
  },

  watch: {
    activation_date(val) {
      this.formCreativeData.activation_date = val;
    },
    expiration_date(val) {
      this.formCreativeData.expiration_date = val;
    }
  },

  methods: {
    async getSites() {
      this.onLoading(true);
      try {
        const { data, status } = await this.getAllSites();
        if (status === 200) this.sitesList = data;
      } catch (error) {
        console.error(error);
      } finally {
        this.onLoading(false);
      }
    },

    filterSites(val, update) {
      update(this.defaultFilter(val, 'sitesFiltered', this.sitesList, 'name'));
    }
  },

  created() {
    this.getSites();
  }
};
</script>
