<template>
  <div class="col-md-4">
    <q-card flat bordered>
      <q-card-section class="text-center">
        <q-img v-if="images" :src="parceToUrl(images)" contain />
      </q-card-section>
      <div class="text-center">{{ getNameImage(images) }}</div>
      <q-card-section class="row q-col-gutter-md">
        <div class="col-12">
          <q-input
            v-model="form.title"
            label="Titulo *"
            maxlength="200"
            :rules="[isRequired, (val) => val.length < 200 || 'Limite de 200 caracteres']"
            outlined
          />
        </div>
        <div class="col-12">
          <q-input
            v-model="form.destination_url"
            type="url"
            label="Url do redirecionamento *"
            :rules="[isRequired]"
            outlined
            autogrow
          >
            <template v-slot:append>
              <q-icon
                name="mdi-help-circle-outline"
                class="cursor-pointer"
                size="1.3em"
                color="primary"
                @click="onShowMacros"
              >
                <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Macros </q-tooltip>
              </q-icon>

              <MacroListCreative :showM="showMacros" @closemacro="showMacros = false" />
            </template>
          </q-input>
        </div>
        <div class="col-12">
          <select-request
            :v-model="form.id_banner_size"
            :rules="[isRequired]"
            outlined
            label="Tamanho do banner *"
            endpoint="redirect?app=CREATIVE&path=/api/get/banner_size"
            option-label="size"
            option-value="id"
            @update="(value) => (form.id_banner_size = value)"
          />
        </div>
        <div class="col-12">
          <date-input
            :v-model="form.activation_date"
            @update="form.activation_date = $event"
            outlined
            clearable
            label="Data de Ativação *"
            :rules="[isRequired, isValidDate]"
          />
        </div>

        <div class="col-12">
          <time-input
            :label="'Hora de ativação'"
            @timeUpdate="(val) => (form.activation_time = val)"
            :rules="[isValidTime]"
          />
        </div>

        <div class="col-12">
          <date-input
            :v-model="form.expiration_date"
            @update="form.expiration_date = $event"
            outlined
            clearable
            label="Data de Expiração"
            :rules="[isValidDate]"
          />
        </div>

        <div class="col-12">
          <time-input
            label="Hora de Expiração"
            @timeUpdate="(val) => (form.expiration_time = val)"
            :rules="[isValidTime]"
          />
        </div>

        <div class="col-12">
          <select-request
            :v-model="form.id_category"
            outlined
            label="Categorias"
            endpoint="redirect?app=CREATIVE&path=/api/get/category/filter"
            option-label="name"
            option-value="id"
            @update="(value) => (form.id_category = value)"
          />
        </div>
        <div class="col-12">
          <select-request
            :v-model="form.id_offer"
            outlined
            label="oferta/Sazonalidade"
            endpoint="redirect?app=CREATIVE&path=/api/get/offer/filter"
            option-label="name"
            option-value="id"
            @update="(value) => (form.id_offer = value)"
          />
        </div>
        <div class="col-12">
          <q-select
            v-model="form.site"
            outlined
            multiple
            use-chips
            use-input
            input-debounce="200"
            label="Canal"
            @filter="filterSites"
            :options="sitesFiltered"
            option-label="name"
            option-value="id"
            clearable
            clear-icon="mdi-close"
          />
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import SelectRequest from '@/widgets/inputsGlobal/SelectRequest.vue';
import DateInput from '../../../widgets/inputsGlobal/DateInput.vue';
import MacroListCreative from './MacroListCreative.vue';
import TimeInput from '@/widgets/inputsGlobal/TimeInput.vue';
import CreativeMixin from '../../../mixins/CreativeMixin.js';

export default {
  name: 'CupomCretive',
  components: { DateInput, SelectRequest, MacroListCreative, TimeInput },
  mixins: [CreativeMixin],
  props: {
    images: {
      type: File,
      default: null
    },
    id: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      showMacros: false,
      sitesList: [],
      sitesFiltered: [],
      form: {
        site: null,
        id_type: 5,
        title: null,
        destination_url: null,
        id_banner_size: null,
        id_offer: null,
        id_category: null,
        id_region: '[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27]',
        activation_date: null,
        activation_time: null,
        expiration_date: null,
        expiration_time: null
      },
      date: null
    };
  },
  watch: {
    form: {
      handler() {
        let formEdit = {
          ...this.form,
          image: this.images,
          id: this.id
        };

        this.ActionPushNewImageToCreativeImages(formEdit);
      },
      deep: true
    }
  },
  methods: {
    onShowMacros() {
      this.showMacros = !this.showMacros;
    },
    async getSites() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get('/redirect?app=AFFILIATE&path=/api/affiliate/site/filter');
        if (status === 200) this.sitesList = data;
      } catch (error) {
        console.error(error);
      } finally {
        this.onLoading(false);
      }
    },

    filterSites(val, update) {
      update(this.defaultFilter(val, 'sitesFiltered', this.sitesList, 'name'));
    }
  },
  created() {
    this.getSites();
  }
};
</script>

<style></style>
